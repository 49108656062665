/* requires:
vendor/lazysizes.min.js
vendor/unveilhooks.min.js
vendor/fontfaceobserver.min.js
vendor/backgroundcheck.js
*/

var LCA = (function() {
	// "use strict";    
	// Global Cache
	var $html = $('html').addClass('js').removeClass('no-js'),
        $body = $html.find('body'),
		$main = $body.find('section[role="main"]'),
		page = $body.attr('id'),
        touch = false,
        can_io = "IntersectionObserver" in window,
        yScrollPos;            

	return {
        init: function() {            
            LCA.Global.FontsLoader();
            LCA.Global.MenuRevealer();            
            LCA.Global.TouchEventDetect();
            LCA.Global.ScrollDirection();
            LCA.Projects.Gallery();
            LCA.Projects.PointsOfInterest();
            LCA.Utilities.Obfuscator();
            LCA.Home.InViewAnimation();                   
        },

        loaded: function() {                    
            $('.lazyloading').removeClass();  
            $html.addClass('loaded');                  

            BackgroundCheck.refresh();

            // Force load font
            var timeout;
            clearTimeout(timeout);
            timeout = setTimeout(function(){                    
                $html.removeClass('no-font');
            }, 1500);           
        },

        resize: function() {
            BackgroundCheck.refresh();
        },

        Home: {

            InViewAnimation: function(){
                if(!touch && page === 'index') return;

                // Stop/start within centre of frame
                if(can_io) {

                    var project_buttons = document.querySelectorAll('#project_index > a');

                    LCA.Utilities.ForEach(project_buttons, function (index, value) {

                        var project_buttons_io = document.getElementById(value.id);
                        var isLeaving = false,
                            options = {
                                rootMargin:'-50% 0px -50% 0px'
                            };
                        
                        var observer = new IntersectionObserver(function (entries) {
                              entries.forEach(function (entry) {
                                if (entry.isIntersecting) {
                                  isLeaving = true;
                                  $(project_buttons_io).addClass('iv');
                                } else if (isLeaving) {
                                  isLeaving = false;
                                  $(project_buttons_io).removeClass('iv');
                                }
                              });
                            }, options);

                        observer.observe(project_buttons_io);

                    });

                }
            }

        },

        Projects: {

            Gallery: function(){ 

                var carousels = document.querySelectorAll('.carousel'),
                    is_carousels = carousels.length > 0,
                    galleries = document.querySelectorAll('.gallery'),
                    is_galleries = galleries.length > 0;

                var fade = touch ? false : true;


                if(!is_carousels && !is_galleries) return;                

                if(is_carousels){
                    
                    LCA.Utilities.ForEach(carousels, function (index, value) {

                        var name = "#"+value.id,
                            next = $(name+'-nav .next'),
                            prev = $(name+'-nav .prev');

                        var carousel = $(name).slick({
                            fade: true,
                            speed: 1500,
                            arrows: false,
                            draggable: false,
                            waitForAnimate: false,
                            arrows: true,
                            nextArrow: next,
                            prevArrow: prev
                        }).click(function(e){
                            next.click();
                        });
                    });

                }

                if(is_galleries) {

                    LCA.Utilities.ForEach(galleries, function (index, value) {

                        var name = "#"+value.id;                          

                        var carousel = $(name).slick({
                            autoplay: true,
                            autoplaySpeed: 1000,
                            fade: true,
                            speed: 1500,
                            arrows: false,
                            draggable: false,
                            waitForAnimate: false,
                            pauseOnFocus: false,
                            pauseOnHover: false
                        });

                        // Stop/start if more than 50% in frame
                        if(can_io) {

                            var carousel_io = document.getElementById(value.id);
                            var isLeaving = false,
                                options = {
                                    threshold:[0, 0.33, 0.5, 1.0]
                                };
                            
                            var observer = new IntersectionObserver(function (entries) {

                              entries.forEach(function (entry) {

                                if (entry.isIntersecting && entry.intersectionRatio > 0.45) {
                                  isLeaving = true;                                  
                                  $(entry.target).slick('slickPlay');
                                } else {                                
                                  isLeaving = false;                                  
                                  $(entry.target).slick('slickPause');
                                }

                              });
                            }, options);
                            observer.observe(carousel_io);

                        }
                    });

                }
            },

            PointsOfInterest: function(){

                var $poi = $('#poi');
                
                if(!$poi.length > 0) return;

                $poi.find('#tabs').on('click', 'a', function(event) {
                    event.preventDefault();
                    var selected_tab = $(this).attr('class');                    
                    $(this).parents('#poi').attr('class',selected_tab);                    
                });

            }
        },

        Utilities: {

            ForEach: function (array, callback, scope) {
                
                for (var i = 0; i < array.length; i++) {
                  callback.call(scope, i, array[i]);
                }

            },

            Obfuscator: function() {                
             $.fn.deobfuscate = function(options) {
                 if(this.length) {

                     return this.each(function() {                                                        

                         var ltr, 
                             codes, address = "", coded, key, shift, link = "", linkText = $(this).data('text'), email, coded = $(this).data('coded'); 
                                key = $(this).data('key');

                             shift = coded.length;          

                             for (var i=0; i<coded.length; i++) {
                                 if (key.indexOf(coded.charAt(i)) == -1) {
                                     ltr = coded.charAt(i);
                                     link += (ltr);
                                 } else {     
                                     ltr = (key.indexOf(coded.charAt(i))-shift+key.length) % key.length;
                                     link += (key.charAt(ltr));
                                 }
                             } 

                             linkText = (linkText !== undefined ? linkText : link);
                             email = "<a href='mailto:"+link+"'>"+linkText+"</a>";
                             $(this).html(email);
                     });
                 }
             }
             $(".obfs").deobfuscate();
             }


        },

        Global: {    

            MenuRevealer: function(){                

                var imagesBackgroundCheck = document.querySelectorAll('main > section:first-of-type img');

                if(imagesBackgroundCheck.length > 0) {

                    BackgroundCheck.init({
                        targets: '.loupe',
                        changeParent: true,
                        images: imagesBackgroundCheck,               
                        classes: {dark:'dark', light:'light', complex:'light'}
                    });    

                }                
                
            },

            ScrollDirection: function(){                      

                var scrollPos = (document.body.getBoundingClientRect()).top,
                    direction, oldDirection;     

                if(scrollPos >= 0) {
                    $html.addClass('top');
                }       
                
                window.addEventListener('scroll', function(){                    

                    liveScrollPos = (document.body.getBoundingClientRect()).top;                    

                    if (liveScrollPos > scrollPos){
                        direction = 'up';
                        oldDirection = 'down';
                    } else {                        
                        direction = 'down';
                        oldDirection = 'up';                                                                
                    }                   

                    if(liveScrollPos >= 0) {    
                        direction = 'top';
                        oldDirection = 'up down bottom';    
                    } else if((liveScrollPos + (document.body.clientHeight)) - (window.innerHeight) <= 0) {
                        direction = 'bottom';
                        oldDirection = 'up down top';                
                    } else {                        
                        oldDirection = oldDirection + ' ' + 'top bottom';
                    }

                    $html.addClass(direction).removeClass(oldDirection);                

                    scrollPos = (document.body.getBoundingClientRect()).top;
                });
              
            },

            FontsLoader: function(){                
                
                var timeout,
                    sunrise = new FontFaceObserver('SangBleuSunrise-Regular-WebXL');

                Promise.all([
                    sunrise.load()
                ]).then(function () {
                    $html.removeClass('no-font');
                });            

            },

            TouchEventDetect: function(){                
                
                var bool;                
            
                if (('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch) {
                    bool = true;                    
                    $html.addClass('touch');
                } else {
                    bool = false;
                    $html.addClass('no-touch');
                }           

                touch = bool; 

                return bool;

            }

        }
    }
})();

$(document).ready(LCA.init);
$(window).on("load", LCA.loaded);
$(window).resize(function() {LCA.resize});